import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
var moment = require('moment-timezone');




export const CardTransaction = (props) => {
  const classes = useStyles();
  
  return (
    <Grid item xs={12} key={props.transaction.id} className={classes.container} container>
      <Grid  container className={classes.row}>
        <Grid xs={7} item>
          <Typography className={classes.name}>
            {props.transaction.clientNickname}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.amount}>R$</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography className={classes.amount}>
            {props.transaction.amount.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.row}>
        <Grid xs={8} item>
          <Typography className={classes.date}>
          {  moment(new Date(props.transaction.createdAt.toDate())).tz(moment.tz.guess()).format('MMMM Do YYYY, hh:mm a ')} 
          </Typography>
        </Grid>
        <Grid className={classes.bottom} xs={4} item>
          {props.transaction.type === "income" ? 
            <Typography key={1} className={classes.income}>
              Cash In
            </Typography>
           : 
            <Typography key={2} className={classes.expend}>
              Cash Out
            </Typography>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: "2px solid grey",
    borderRadius: "15px",

    padding: "0.7em",

    marginTop: "0.7em",
    marginBottom: "0.7em",

    height:'min-content',

    [theme.breakpoints.down("sm")]: {
      padding: "0.7em",

      marginTop: "0.7em",
      marginBottom: "0.7em",
    },


    [theme.breakpoints.up("md")]: {
      padding: "0.7em",

      marginTop: "0.7em",
      marginBottom: "0.7em",
    },
  },
  bottom: {
    alignSelf: "end",
  },
  row: {
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
  },
  name: {
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  amount: {
    textAlign: "right",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  date: {
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  income: {
    textAlign: "right",
    alingSelf: "end",
    color: "green",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  expend: {
    textAlign: "right",
    alingSelf: "end",
    color: "red",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
}));
