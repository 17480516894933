import React from 'react';
import { makeStyles, CircularProgress } from "@material-ui/core";

export const Loading = () => {
    const classes = useStyles();
    return (
        <div className={classes.loading}><CircularProgress style={{width:'105px', height: '105px'}}/></div>
    )
}

const useStyles = makeStyles((_) => ({
    loading: {
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))