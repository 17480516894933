import React, { useEffect, createContext, useState } from 'react'
import { auth, firestore } from '../firebase'
import { Loading } from '../components/Loading'

export const AuthenticationContext = createContext()

export const withAuthentication = (Component) => () => {
    const [isLoadingAuth, setIsLoadingAuth] = useState(true)
    const [user_, setUser_] = useState(null)

    useEffect(() => {
        const unsubscribeAuthStateChanged = auth.onAuthStateChanged(async (user) => {
            if(user) {
                const ans = await firestore.collection("users").doc(user.uid).get()
                if (ans.data()["role"] === "admin") {
                    setUser_(user)
                }
            } else setUser_(null)
            setIsLoadingAuth(false)
        })

        return () => unsubscribeAuthStateChanged()
    }, [])

    return isLoadingAuth ? (
        <Loading/>
    ) : (
        <AuthenticationContext.Provider value={[user_, setIsLoadingAuth]}>
            <Component />
        </AuthenticationContext.Provider>
    )
}