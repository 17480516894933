import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import  {makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import React from 'react'


 export default (props) =>{
    const classes = useStyles();
    const history = useHistory();

    return(
        <Card classes={{root: classes.card}} elevation={3}>
        <CardActionArea classes={{root: classes.action}} onClick={() => { history.push(`/casino/${props.casino}/machines/${props.machine.id}`) }}>
        
            <CardContent classes={{root: classes.content}}>
            <Typography>
            Terminal No. {props.machine.serialNumber}
            </Typography>
            </CardContent>
        </CardActionArea>
        
        </Card>
    )

}

const useStyles = makeStyles(theme => ({
    card: {
        
        width: '250px',
        minHeight: '60px',
        margin: '20px',
        borderRadius:'15px',
        display:'flex',
        
    },
    action: {
    
        display:'flex',
        
    },
    content: {
        justifyContent: 'center',
        margin:'auto',
        "&:last-child": {
          paddingBottom: '16px'
        }
    }
}))
