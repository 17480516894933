import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt'
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Container, Grid, makeStyles, colors, Button, Box } from '@material-ui/core';
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { firestore } from '../../../firebase';
import { querySnapshotToArray, filterQuerySnapshot } from '../../../utils/snapshotToArray';
import { CardTransaction } from '../../../components/CardTransaction';
import { Loading } from '../../../components/Loading';
import { useParams } from 'react-router-dom';
import { CardTransactionWorker } from './CardTransactionWorker';
import { CardTransactionMachine } from './CardTransactionMachine';
import Typography from "@material-ui/core/Typography";

const endDay = new Date();
endDay.setDate(endDay.getDate() + 1);

const schema = yup.object().shape({
    startAt: yup.date().max( new  Date(endDay) , "La fecha inicial no debe pasar de hoy").required("Es necesario decir la fecha inicial"),
    endAt: yup.date().min(yup.ref("startAt"), "La fecha final no puede ser antes que la inicial")
    .max(new Date(endDay), "La fecha final no debe pasar de hoy")
    .required("Es necesario decir la fecha final")
})

export const Transactions = () => {
    const classes = useStyles();
    const { control, handleSubmit, errors } = useForm({
        defaultValues: {
            startAt:new Date(Date.now()),
            endAt: new Date(Date.now())},
        resolver: yupResolver(schema)
    });
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [movements, setMovements] = useState([]);
    const params = useParams();

    const fetchTransactions = async (startAt, endAt) => {
        setLoading(true);
        const transactionsSnapshot = await firestore.collection("transactions")
                            .where("createdAt", ">=", startAt)
                            .where("createdAt", "<=", endAt)
                            .orderBy("createdAt", 'desc')
                            .limit(10)
                            .get();

        const movementsSnapshot = await firestore.collection("casinos")
                            .doc(params.casinoId)
                            .collection("movements")
                            .where("createdAt", ">=", startAt)
                            .where("createdAt", "<=", endAt)
                            .orderBy("createdAt", 'desc')
                            .limit(10)
                            .get();

        setTransactions(filterQuerySnapshot(transactionsSnapshot, params.casinoId));
        setMovements(querySnapshotToArray(movementsSnapshot));
        setLoading(false);
    }

    function renderTransactionsCards() {
        if(transactions.length > 0) {
            return(
                <div>
                    <Box>
                        <Typography className={classes.subtitle}>Últimos 10 movimentos no caixa</Typography>
                    </Box>
                    <Box>
                        <Grid container item xs={12} lg={9}>
                            <Grid item xs={3}  className={classes.name}>
                                Usuario
                            </Grid>
                            <Grid item xs={3}  className={classes.amount}>
                                Quantia
                            </Grid>
                            <Grid item xs={3}  className={classes.name}>
                                Trabalhador
                            </Grid>
                        </Grid>
                        {
                            transactions.map((element, index) => (
                            <CardTransactionWorker key={index} transaction={element} />
                            ))
                        }
                    </Box>
                </div>
            )
        } else {
            return <Box className={classes.emptytrans}>Não há movimentos no caixa</Box>
        }
    }

    function renderMovementsCards() {
        if(movements.length > 0) {
            return(
                <div>
                    <Box>
                        <Typography className={classes.subtitle} >Últimos 10 movimentos nos terminais</Typography>
                    </Box>
                    <Grid container item xs={12} lg={9}>
                        <Grid item xs={3} className={classes.name}>
                            Usuario
                        </Grid>
                        <Grid item xs={3} className={classes.amount}>
                            Quantia
                        </Grid>
                        <Grid item xs={3} className={classes.name}>
                            Terminal
                        </Grid>
                    </Grid>
                    <Box>
                        {
                            movements.map((element, index) => (
                            <CardTransactionMachine key={index} transaction={element} />
                            ))
                        }
                    </Box>
                </div>
            )
        } else {
            return <Box className={classes.emptytrans}>Não há movimentos nos terminais</Box>
        }
    }

    const onSubmit = (data) => {
        console.log(data);
        fetchTransactions(data['startAt'], data['endAt'])
    }

    return (
        <Container>
        <div className={classes.container}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.button}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                            <Controller
                            as={<DateTimePicker
                                label="Inicio"
                                inputVariant="outlined"
                                error={!!errors.startAt}
                                helperText={errors.startAt && errors.startAt.message}
                                value= ""
                                required
                                onChange={() => { }} />}
                            name="startAt" control={control} />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                                <Controller
                                as={<DateTimePicker
                                    label="Fim"
                                    inputVariant="outlined"
                                    error={!!errors.endAt}
                                    helperText={errors.endAt && errors.endAt.message}
                                    value=""
                                    required
                                    onChange={() => { }} />}
                                name="endAt" control={control} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button}>
                    <Button type='submit'>Procurar movimentos</Button>
                </div>
            </form>
        </div>
        <div>
            {
                loading ? 
                <Loading/>
                : <div>
                    {
                        renderTransactionsCards()
                    }
                    {
                        renderMovementsCards()  
                    }
                </div> 
            }
        </div>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    subtitle:{
        fontSize:'1.5em',
        marginTop:'1em',
        marginBottom:'1em'
    },
    container: {
        background: colors.grey.A700,
        padding: '16px'
    },
    button: {
        padding: '16px'
    },
    emptytrans: {
        padding: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    name: {
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
    },
    amount: {
        overflow: "auto",
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
    },
}))