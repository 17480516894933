import firebase from 'firebase'
import 'firebase/firestore'

firebase.initializeApp({
    apiKey: "AIzaSyDxHluKwtv4sdrinVSZoLj2CRqwRHM9PXs",
    authDomain: "poker-92727.firebaseapp.com",
    databaseURL: "https://poker-92727.firebaseio.com",
    projectId: "poker-92727",
    storageBucket: "poker-92727.appspot.com",
    messagingSenderId: "175142747730",
    appId: "1:175142747730:web:97f69a69a3550b1d6f698b",
    measurementId: "G-5W378FHL3E"
});

const secondaryAuth = firebase.initializeApp({
    apiKey: "AIzaSyDxHluKwtv4sdrinVSZoLj2CRqwRHM9PXs",
    authDomain: "poker-92727.firebaseapp.com",
    databaseURL: "https://poker-92727.firebaseio.com"
}, "SECONDARY")

const firestore = firebase.firestore()
const storage = firebase.storage()
const auth = firebase.auth()

const signIn = async(email, password) => {
    try {
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        await auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
        console.log('error', error)
        throw(error)
    }
}

export { firestore, auth, storage, signIn, secondaryAuth }