import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthenticationContext } from '../hoc'

export const PrivateRoute = (props) => {
    const [user_] = useContext(AuthenticationContext)
    return (
      <Route
        exact
        path={props.path}
        render={() => (user_ ? props.children : <Redirect to='/' />)}
      />
    )
  }
  