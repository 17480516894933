import React from 'react';
//import { Grid } from '@material-ui/core';
import logo from  '../utils/404.jpg';
import { makeStyles } from "@material-ui/core";
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const NotFound = () => {
    const classes = useStyles();
    const history = useHistory();
    
         //<Button className={classes.btn} onClick={history.push("/")}>Home</Button>
    return(
        <div>
         <img className={classes.errorImg}src={logo} alt="Logo" />

         </div>
             )
}

const useStyles = makeStyles((theme) => ({
errorImg: {
    display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop:"40px",
    [theme.breakpoints.up("xs")]: {
      width: "300px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.up("md")]: {
      width: "700px",
    },
    
  },
  btn: {
    display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop:"10px",
    
  },

}));