import React, { useEffect, useState } from 'react'
import CardMachine from './cardMachine' 
import { firestore } from '../../../firebase'
import { querySnapshotToArray } from '../../../utils/snapshotToArray'
import Box from '@material-ui/core/Box';
import { useParams } from "react-router";
import { Container } from '@material-ui/core';
import { Loading } from '../../../components/Loading';

export const Machines = () => {
    const [machines, setMachines] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        fetchMachines()
    }, [])

    const { casinoId } = useParams();

    
    const fetchMachines = async () => {
        
        
        const machines = await firestore.collection("casinos").doc(casinoId).collection('machines').orderBy('serialNumber').get()
        setMachines(querySnapshotToArray(machines))
        setIsLoading(false)
    }

    function renderCardsMachines (machines){
        //console.log(machines);
        
        if (machines.length>0) {
            return(
                <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" paddingTop="3em">
                {        
                    machines.map(element => <CardMachine key={element.id} machine={element} casino={casinoId}></CardMachine>)
                }        
                </Box>)
        }else{
            return(<div>No hay maquinas</div>)
        }

    }
    
    
    return (
        <Container>
            {
                isLoading ?
                <Loading/>
                : renderCardsMachines(machines)
            }
        </Container>
        
        )
        
    
}