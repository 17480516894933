import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import theme from './theme'
import { withAuthentication } from './hoc';

function App() {

  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default withAuthentication(App);
