import Typography from "@material-ui/core/Typography";
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from "@material-ui/core";
import React from 'react';
import Grid from '@material-ui/core/Grid';
var moment = require('moment-timezone');
//import moment from "moment";
//import 'moment/locale/pt-br';

export const CardTransactionMachine = (props) => {
    const classes = useStyles();
    //var momentPt = moment
    //momentPt.locale("pt-br");
    console.log(moment.tz.guess());


    return (
        <Grid item xs={12} lg={9}container className={classes.container}>
            <Grid container>
                <Grid item xs={3} className={classes.name}>
                    {props.transaction.clientNickname}
                </Grid>
                <Grid item xs={3} className={classes.amount}>
                    R$ {props.transaction.amount.toFixed(2)}
                </Grid>
                <Grid item xs={3} md={2} lg={1} className={classes.name}>
                    {props.transaction.machineSerialNumber}
                </Grid>
                <Grid className={classes.bottom} xs={3} md={1} item>
                    {props.transaction.type === "income" ? 
                        <Typography key={1} className={classes.income}>
                        Cash In
                        </Typography>
                    : 
                        <Typography key={2} className={classes.expend}>
                        Cash Out
                        </Typography>
                    }
                </Grid>
                <Hidden smDown>
                    <Grid item md={3} lg={4} className={classes.date}>
                            {  moment(new Date(props.transaction.createdAt.toDate())).tz(moment.tz.guess()).format('MMMM Do YYYY, hh:mm a ')}
                    </Grid>    
                </Hidden>
            </Grid>
            <Hidden mdUp>
                <Typography className={classes.date}>
                {  moment(new Date(props.transaction.createdAt.toDate())).tz(moment.tz.guess()).format('MMMM Do YYYY, hh:mm a ')}</Typography>
            </Hidden>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    
    name: {
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
    },
    date: {
        textAlign: "right",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
    },
    income: {
        textAlign: "right",
        alingSelf: "end",
        color: "green",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
    },
    expend: {
        textAlign: "right",
        alingSelf: "end",
        color: "red",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
    },
    amount: {
        overflow: "auto",
        [theme.breakpoints.down("md")]: {
          fontSize: "18px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
    },
    container: {
        border: "2px solid grey",
        borderRadius: "15px",
    
        padding: "0.7em",
    
        marginTop: "0.7em",
        marginBottom: "0.7em",
    
        height:'min-content',
    
        [theme.breakpoints.down("sm")]: {
          padding: "0.7em",
    
          marginTop: "0.7em",
          marginBottom: "0.7em",
        },
    }
}))