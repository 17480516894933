import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { Layout } from '../layout'
import { Login } from '../pages/login'
import { Home } from '../pages/home'
import { Workers } from '../pages/home/workers'
import { Machines } from '../pages/home/machines'
import { MachineHome } from '../pages/home/machines/machineHome'
import { WorkerHome } from '../pages/home/workers/WorkerHome'
import { Transactions } from '../pages/home/transactions'
import { NotFound } from '../components/NotFound'

export const Routes = () => {
    return (
        <Switch>
            <Route exact path={'/'} component={Login}/>
            <PrivateRoute exact path="/home">
                <Layout>
                    <Home />
                </Layout>
            </PrivateRoute>
            <PrivateRoute exact path="/casino/:casinoId/workers">
                <Layout>
                    <Workers/>
                </Layout>
            </PrivateRoute>
            <PrivateRoute exact path="/casino/:casinoId/machines">
                <Layout>
                    <Machines/>
                </Layout>
            </PrivateRoute>
            <PrivateRoute exact path="/casino/:casinoId/machines/:machineId">
                <Layout>
                    <MachineHome/>
                </Layout>
            </PrivateRoute>
            <PrivateRoute exact path="/casino/:casinoId/workers/:workerId">
                <Layout>
                    <WorkerHome/>
                </Layout>
            </PrivateRoute>
            <PrivateRoute exact path="/casino/:casinoId/transactions">
                <Layout>
                    <Transactions/>
                </Layout>
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>
    )
}