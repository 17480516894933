export const querySnapshotToArray = snapshot => {
    const returnArray = [];

    snapshot.forEach(childSnapshot => returnArray.push({...childSnapshot.data(), id: childSnapshot.id}));

    return returnArray;
};


export const filterQuerySnapshot = (snapshot, casinoId) => {
    const returnArray = [];
    snapshot.forEach(function(childSnapshot) { 
        if (childSnapshot.data().casinoId == casinoId) {
            returnArray.push({...childSnapshot.data(), id: childSnapshot.id});
        }
    });
    return returnArray;
}