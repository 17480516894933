import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Casino from "@material-ui/icons/Casino";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import MenuIcon from "@material-ui/icons/Menu";
import Face from "@material-ui/icons/Face";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { makeStyles, createStyles, IconButton } from "@material-ui/core";
import { auth } from "../firebase";
import { AuthenticationContext } from "../hoc";
import { useLocation, useHistory, useParams, matchPath } from 'react-router-dom'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DockIcon from '@material-ui/icons/Dock';

const drawerWidth = 240;

export const Layout = ({ children }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  
  const [, setIsLoadingAuth] = useContext(AuthenticationContext)

  const signOut = async () => {
    setIsLoadingAuth(true)
    await auth.signOut()
  }
  
  //usar esta funcion para agregar estilos a los items trabajadores y maquinas
  // const matchUrl = () => {
  //   return location.pathname.includes("machines")
  // }
  // console.log(matchUrl())
  
  const drawer = (
    <div>
      <List>
        <ListItem button onClick={() => history.push("/home")} >
          <ListItemIcon>
            <Casino />
          </ListItemIcon>
          <ListItemText primary={"Salas"} />
        </ListItem>
          {
            location.pathname !== "/home" &&
            <React.Fragment>
            <ListItem button className={classes.nested} onClick={() => history.push(`/casino/${params.casinoId}/machines`)}>
              <ListItemIcon>
                <DockIcon />
              </ListItemIcon>
              <ListItemText primary="Terminais" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => history.push(`/casino/${params.casinoId}/workers`)}>
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Trabalhadores" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => history.push(`/casino/${params.casinoId}/transactions`)}>
              <ListItemIcon>
                <SwapHoriz />
              </ListItemIcon>
              <ListItemText primary="Movimentos" />
            </ListItem>
            </React.Fragment>
          }
          
            
        <ListItem button disabled>
          <ListItemIcon>
            <Face />
          </ListItemIcon>
          <ListItemText primary={"Perfil"} />
        </ListItem>
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={"Sair"} />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            RCT Cash
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp>
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {},
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up("md")]: {
        top: "65px",
      },
    },
    content: {
      flexGrow: 1,
      //padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(12),
        
      }
  })
);
