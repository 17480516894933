import { makeStyles } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useHistory } from "react-router-dom";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import PersonIcon from "@material-ui/icons/Person";
import Grid from "@material-ui/core/Grid";

export default (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card classes={{ root: classes.card }} elevation={3}>
      <CardActionArea
        classes={{ root: classes.action }}
        onClick={() => {
          history.push(`/casino/${props.casino}/workers/${props.worker.id}`);
        }}
      >
        <CardContent classes={{ root: classes.content }}>
          <Grid container>
            <Grid xs={12} item>
              <Typography>Nome:</Typography>
            </Grid>
            <Grid xs={2} item>
              <PersonIcon></PersonIcon>
            </Grid>
            <Grid xs={10} item>
              <Typography>{props.worker.name} { props.worker.lastName}</Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography className={classes.email}>E-mail:</Typography>
            </Grid>
            <Grid item>
              <Typography>{props.worker.email}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: "250px",
    minHeight: "60px",
    margin: "20px",
    borderRadius: "15px",
    display: "flex",
  },
  action: {
    display: "flex",
  },
  content: {
    justifyContent: "center",
    margin: "auto",
    "&:last-child": {
      paddingBottom: "16px",
    },
    email:{
        marginTop:"0.5em"
    }
  },
}));
