import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Button } from "@material-ui/core";
import { firestore, storage } from "../../firebase";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from 'react-router-dom'


import React from "react";

export const CardCasino = (props) => {
  const classes = useStyles();
      const history = useHistory()


  const handleChange = (e) => {
    if (e.target.files[0]) {
      console.log(props.casino);
      const image = e.target.files[0];
      console.log(image);
      const uploadTask = storage
        .ref(`images/casino-${props.casino.id}`)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log(
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(`casino-${props.casino.id}`)
            .getDownloadURL()
            .then(async (url) => {
              console.log(url);
              const casinoRef = firestore
                .collection("casinos")
                .doc(props.casino.id);
              await casinoRef.update({ imgUrl: url });
              props.setCasinos((_) => {
                            let newCasinos = [...props.casinos]
                            const index = props.index
                            newCasinos[index].imgUrl = url
                            return newCasinos
                        })
            });
        }
      );
    }
  };
  console.log(props.casino)
  return (
    <Card className= {classes.card}>
      {props.casino.imgUrl ? (
          <CardMedia
            className={classes.media}
            image={props.casino.imgUrl}
            title="casino img"
          ></CardMedia>
        ) : (
          <div className={classes.withoutImage}>
            <input
              accept="image/*"
              className={classes.input}
              id="raised-button-file"
              multiple
              type="file"
              onChange={(event) => handleChange(event)}
            />
            <label htmlFor="raised-button-file">
              <IconButton component="span">
                <InsertPhoto className={classes.insertIcon} />
              </IconButton>
            </label>
          </div>
        )}
        <CardContent>
          <Typography className={classes.title} variant="h5">{props.casino.name}</Typography>
          <Typography variant="subtitle1">Endereço</Typography>
          <Typography variant="body1">{props.casino.address}</Typography>
          <Button onClick={() => history.push(`/casino/${props.casino.id}/machines`)}>Entrar</Button>
        </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
    media: {
    height: 140,
  },
  title:{
      marginBottom:"10px"
  },
  
  withoutImage: {
    minHeight: "180px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  insertIcon: {
    fontSize: "80px",
    color: theme.palette.secondary.dark,
  },
  input: {
    display: "none",
  },
  card:{
      width:"250px",
      margin: '32px'
  }
}));
