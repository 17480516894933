import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core";
import { firestore } from "../../../firebase";
import { querySnapshotToArray } from "../../../utils/snapshotToArray";
import { useParams } from "react-router";
import { CardTransaction } from "../../../components/CardTransaction";
import { Loading } from "../../../components/Loading";

export const WorkerHome = () => {
  const classes = useStyles();
  const { casinoId, workerId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [worker, setWorker] = useState({});
  const [workerMoneyInOut, setWorkerMoneyInOut] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const handleChangeIsDisabled = async (_) => {
    firestore
      .collection("users")
      .doc(workerId)
      .update({ isDisabled: !isDisabled })
      .then(setIsDisabled(!isDisabled));
  };

  useEffect(() => {
    fetchData();
  }, []);

  function renderTransactions() {
    return transactions.map((element, index) => (
      <CardTransaction transaction={element} key={index}></CardTransaction>
    ));
  }

  const fetchData = async () => {
    const transactionsCollRef = firestore.collection("transactions");

    const workerMoneyInOut = await firestore
      .collection("casinos")
      .doc(casinoId)
      .collection("workers")
      .doc(workerId)
      .get();

    setWorkerMoneyInOut(workerMoneyInOut.data());
    const transactionSnapshot = await transactionsCollRef
      .where("workerId", "==", workerId)
      .orderBy("createdAt")
      .limit(10)
      .get();

    const workerSnapshot = await firestore
      .collection("users")
      .doc(workerId)
      .get();
    setWorker(workerSnapshot.data());
    setIsDisabled(workerSnapshot.data().isDisabled);
    setTransactions(querySnapshotToArray(transactionSnapshot));

    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box
      className={classes.container}
      width="100%"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.data} container  item xs={12} sm={8} md={6} lg={6}>
        <Grid>
          <Typography className={classes.title}>
            Trabalhador {worker.name} {worker.lastName}
          </Typography>
        </Grid>
        <Grid className={classes.row} container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.content}>Total Cash In</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.money}>R$</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.money}>
              {workerMoneyInOut.moneyOutTotal}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row} container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.content}>Total Cash Out</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.money}>R$</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.money}>
              {workerMoneyInOut.moneyInTotal}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row} container>
          <Grid item xs={6}>
            <Typography className={classes.content}>Habilitado</Typography>
          </Grid>
          <Grid item xs={6}>
            <Switch
              checked={!isDisabled}
              onChange={handleChangeIsDisabled}
              color="primary"
              name="isDisabled"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classes.transactions}
        item
        xs={10}
        sm={8}
        md={5}
        lg={5}
        xl={4}
      >
        {transactions.length > 0 ? (
          <div>
            <Typography className={classes.title}>
              Últimas 10 transações
            </Typography>
            {renderTransactions()}
          </div>
        ) : (
          <Typography className={classes.title}>Sem Transações</Typography>
        )}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: "2em",
    paddingRight: "2em",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1em",
    },
  },
  container: {
    height: "80vh",
  },
  money: {
    textAlign: "right",
    paddingRight: "1em",
  },
  transactions: {
    alignContent: "start",
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      height: "80vh",
    },
  },
  data: {
    paddingTop: "3em",
    paddingLeft: "1em",
  },
  row: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  title: {
    marginTop: "1em",
    fontSize: "1.5em",
  },
}));
