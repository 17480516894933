import React, { useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  TextField,
  makeStyles,
  Button,
  Snackbar,
  Box
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { firestore, secondaryAuth } from "../../../firebase";
import { useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AddWorker = (props) => {
  const passwordRegex = /^[A-Za-z\d]{6,17}$/
  const schema = yup.object().shape({
    name: yup.string().required("Ingresar este campo").min(2),
    lastName: yup.string().required("Ingresar este campo").min(2),
    email: yup
      .string()
      .required("Ingresa el email")
      .email("Ingresa el email correctamente"),
    password: yup.string().matches(passwordRegex, "Senha de 6 a 17 caracteres alfanumericos"),
    documentId: yup.string().matches(/^\d{11}$/, "Ingresar 11 digitos"),
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  const params = useParams();
  const classes = useStyles();
  const handleClose = () => {
    props.onClose();
  };
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    error: false,
    message: "",
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const userCredential = await secondaryAuth
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password);
      await firestore.collection("users").doc(userCredential.user.uid).set({
        name: data.name,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        documentId: data.documentId,
        role: "worker",
        casinoId: params.casinoId,
        createdAt: new Date(),
        isDisabled: false,
      });
      await firestore
        .collection("casinos")
        .doc(params.casinoId)
        .collection("workers")
        .doc(userCredential.user.uid)
        .set({
          moneyOutTotal: 0,
          moneyInTotal: 0,
        });
      setIsLoading(false);
      setSnack({
        open: true,
        error: false,
        message: "Trabajador registrado exitosamente",
      });
    } catch (e) {
      setIsLoading(false);
      setSnack({ open: true, error: true, message: e.message });
    }
  };

  return (
    <div>
      <Snackbar
        className={classes.snackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snack.open}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert severity={`${snack.error ? "error" : "success"}`}>
          {snack.message}
        </Alert>
      </Snackbar>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade  in={props.open}>
          <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              maxHeight="100vh"
              justify="center"
              alignItems="center"
              alignContent="center"
              overflow="auto"
              
            >
              <Grid
                container
                spacing={6}
                className={classes.paper}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={10}>
                  <div className={classes.header}>Novo Trabalhador</div>
                </Grid>
                <Grid item xs={11} sm={10}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="name"
                    inputRef={register}
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                  />
                </Grid>
                <Grid item xs={11} sm={10}>
                  <TextField
                    fullWidth
                    label="Sobrenome"
                    name="lastName"
                    inputRef={register}
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                </Grid>
                <Grid item xs={11} sm={10}>
                  <TextField
                    fullWidth
                    label="E-mail"
                    name="email"
                    inputRef={register}
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                  />
                </Grid>
                <Grid item xs={11} sm={10}>
                  <TextField
                    fullWidth
                    label="Senha"
                    name="password"
                    inputRef={register}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                  />
                </Grid>
                <Grid item xs={11} sm={10}>
                  <TextField
                    fullWidth
                    label="CPF"
                    name="documentId"
                    inputRef={register}
                    error={!!errors.documentId}
                    helperText={errors.documentId && errors.documentId.message}
                  />
                </Grid>
                <Grid item xs={11} sm={10}>
                  <Button
                    style={{ width: "100px" }}
                    type="submit"
                    disabled={isLoading}
                  >
                    Registrar
                  </Button>
                  <Button
                    style={{ width: "100px", marginLeft: "10px" }}
                    color="secondary"
                    disabled={isLoading}
                    variant="outlined"
                    onClick={() => props.onClose()}
                  >
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.primary.contrastText,
    border: "2px solid #000",
    overflow: "auto",
    margin:'auto',
    //padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
  },
  header: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    margin: "10px 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: "35px",
    },
  },
  snackbar: {
    [theme.breakpoints.up("sm")]: {
      top: "100px",
    },
  },
  
}));
