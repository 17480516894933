import React, { useEffect, useState } from 'react'
import { firestore, auth, storage } from '../../firebase'
import { querySnapshotToArray } from '../../utils/snapshotToArray'
import { makeStyles, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {CardCasino} from './CardCasino'
import { Loading } from "../../components/Loading";

export const Home = () => {
    const [casinos, setCasinos] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const classes = useStyles()
    useEffect(() => {
        fetchCasinos()
    }, [])

    const fetchCasinos = async () => {
        const casinos = await firestore.collection("casinos").where("ownerId","==",auth.currentUser.uid).get()
        setCasinos(querySnapshotToArray(casinos))
        setIsLoading(false)
    }

    function renderCasinos() {
        if (casinos.length > 0) {
      return (
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          paddingTop="3em"
        >
            {casinos.map((casino, index) =>
             <CardCasino key={casino.id} 
                        casino={casino} 
                        setCasinos={setCasinos} 
                        casinos={casinos}
                        index={index} />) }
        </Box>
        )
    }else{
        return <p>no hay casinos</p>
    }}

    return isLoading ? <Loading />
    : renderCasinos()
    
}

const useStyles = makeStyles(theme => ({
    casino: {
        maxWidth: '720px',
        height: '250px !important'
    },
    rootGridList: {
        justifyContent: 'center'
    },
    withoutImage: {
        minHeight: '180px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    insertIcon: {
        fontSize: '80px',
        color: theme.palette.secondary.dark
    },
    input: {
        display: 'none'
    },
    img: {
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%'
    },
    btnCasino: {
        padding: '0',
        fontSize: '10px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
            height: '32px'
        }
    }
}))