import { querySnapshotToArray } from "../../../utils/snapshotToArray";
import { makeStyles, Container, Fab } from "@material-ui/core";
import { firestore } from "../../../firebase";
import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import { useParams } from "react-router";
import { AddWorker } from "./AddWorker";
import Box from "@material-ui/core/Box";
import CardWorker from "./CardWorker";
import { Loading } from "../../../components/Loading";

export const Workers = () => {
  const [workers, setWorkers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { casinoId } = useParams();

  useEffect(() => {
    fetchWorkers();
  }, []);

  const fetchWorkers = async () => {
    const workers = await firestore
      .collection("users")
      .where("casinoId", "==", casinoId)
      .orderBy("name")
      .get();

    setWorkers(querySnapshotToArray(workers));

    setIsLoading(false);
  };

  function renderCardsWorkers() {
    if (workers.length > 0) {
      return (
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          paddingTop="3em"
        >
          {workers.map((element) => (
            <CardWorker
              key={element.id}
              worker={element}
              casino={casinoId}
            ></CardWorker>
          ))}
        </Box>
      );
    } else {
      console.log(workers);

      return <div>No hay workers</div>;
    }
  }

  return (
    <Container>
      {isLoading ? <Loading/> : renderCardsWorkers(workers)}

      <Fab
        onClick={() => {
          setOpen(true);
        }}
        style={{ width: "auto", height: "auto" }}
        className={classes.addWorker}
      >
        <AddIcon className={classes.iconLogo} />
      </Fab>
      <AddWorker
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  addWorker: {
    margin: 0,
    top: "auto",
    right: 40,
    bottom: 40,
    left: "auto",
    position: "fixed",
  },
  iconLogo: {
    margin: "3px",
    [theme.breakpoints.down("md")]: {
      fontSize: 50,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 70,
    },
  },
}));
