import React, { useState, useContext } from "react";
import {
  Box,
  makeStyles,
  TextField,
  
  Typography,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { signIn } from "../../firebase";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import { AuthenticationContext } from "../../hoc";

export const Login = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Ingresa tu email")
      .email("Ingresa tu email correctamente"),
    password: yup.string().required("Ingresa tu clave"),
  });
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  const history = useHistory();
  const [, setIsLoadingAuth] = useContext(AuthenticationContext);

  const onSubmit = async (data) => {
    try {
      setIsLoadingAuth(true);
      await signIn(data.email, data.password);
    } catch (error) {
      console.log(error);
      setIsLoadingAuth(false);
    }
    return history.push("/home");
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)} >
    <Box display="flex" className={classes.container} height="100vh" justify="center"
      alignItems="center"
      alignContent="center"
      >
      
      <Grid container spacing={7} justify="center" direction="column"
  alignItems="center" className={classes.grid}>
        <Grid  item >
          <Typography variant="h3" className={classes.title}>
            Sala
          </Typography>
        </Grid>
        <Grid item className={classes.textfield}>
          <TextField
            fullWidth
            label="E-mail"
            inputRef={register}
            name="email"
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />
        </Grid>
        <Grid item className={classes.textfield}>
          <TextField
            fullWidth
            label="Senha"
            name="password"
            inputRef={register}
            type={showPassword ? "text" : "password"}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item >
        <Button  variant="contained" type = "submit" >Iniciar</Button>
        </Grid>
      </Grid>
    </Box>
      </form>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.primary.dark,
      //alignItems: "baseline",
    },
  },
  textfield: {
    minWidth: "278px"
  },
  login: {
    backgroundColor: theme.palette.primary.contrastText,
    width: "488px",
    height: "320px",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0",
      padding: "40px 7px",
    },
  },
  title: {
    color: theme.palette.secondary.main,
  },
}));
