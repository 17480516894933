import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core";
import { firestore } from "../../../firebase";
import { querySnapshotToArray } from "../../../utils/snapshotToArray";
import { useParams } from "react-router";
import { CardTransaction } from "../../../components/CardTransaction";
import { Loading } from "../../../components/Loading";

export const MachineHome = () => {
  const classes = useStyles();
  const { casinoId, machineId } = useParams();
  const [movements, setMovements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [machine, setMachine] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const handleChangeIsDisabled = async () => {
    firestore
      .collection("casinos")
      .doc(casinoId)
      .collection("machines")
      .doc(machineId)
      .update({ isDisabled: !isDisabled })
      .then(setIsDisabled(!isDisabled));
  };

  useEffect(() => {
    fetchData();
  }, []);

  function renderTransactions() {
    console.log(movements);
    return (
      
      movements.map((element) => (
      <CardTransaction key={element.id} transaction={element}></CardTransaction>
    )));
  }

  const fetchData = async () => {
    const machineDocRef = firestore
      .collection("casinos")
      .doc(casinoId)
      .collection("machines")
      .doc(machineId);

    const machine = await machineDocRef.get();
    const machineData = machine.data();

    const movementsSnapshot = await firestore
      .collection("casinos")
      .doc(casinoId)
      .collection("movements")
      .where("machineSerialNumber", "==", machineData.serialNumber)
      .orderBy("createdAt", "desc")
      .limit(10)
      .get();

    setMachine(machineData);
    setIsDisabled(machineData.isDisabled);
    setMovements(querySnapshotToArray(movementsSnapshot.docs));
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading/>;
  }

  return (
    <Box className={classes.container}
    width="100%"  
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
  >

      <Grid container className={classes.data} item xs={12} sm={8} md={6} lg={6} >
      
        <Grid item xs={12}>
          <Typography className={classes.title}>
            Terminal No. {machine.serialNumber}{" "}
          </Typography>
        </Grid>
        <Grid className={classes.row} container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.content}>Total Cash In</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.money}>R$</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.money}>
              {machine.moneyInTotal}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row} container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.content}>Total Cash Out</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.money}>R$</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.money}>
              {machine.moneyOutTotal}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row} container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.content}>Coin In </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.money}>R$</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.money}>
              {machine.coinIn}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row} container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.content}>Coin Out </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.money}>R$</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.money}>
              {machine.coinOut}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row} container item xs={12}>
          <Grid item xs={6}>
            <Typography className={classes.content}>Habilitado</Typography>
          </Grid>
          <Grid item xs={6}container alignItems="center" display="flex">
            <Switch
              checked={!isDisabled}
              onChange={handleChangeIsDisabled}
              color="primary"
              name="isDisabled"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.transactions} item xs={10} sm={8} md={5} lg={5} xl={4}>
        {movements.length > 0 ? (
          <div>
          <Typography className={classes.title}>Últimas 10 transações</Typography>
          {renderTransactions()}
          </div>
        ) : (
          <Typography className={classes.title}>Sem Transações</Typography>
        )}
      </Grid>

    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: "2em",
    paddingRight:'2em',
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1em",
    },
  },
  container:{
    height:'80vh'
  },
  money: {
    textAlign: "right",
    paddingRight: "1em",
  },
  transactions: {
    alignContent:"start",
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      height: '80vh'
    },
  },
  data: {
    paddingTop: "3em",
    paddingLeft: "1em",
    //heigth:'100vh'
  },
  row: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  title: {

    marginTop: "1em",
    fontSize: "1.5em",
  },
}));
