import { createMuiTheme } from "@material-ui/core/styles";
import { green, yellow } from '@material-ui/core/colors';

// Create a theme instance.
export default createMuiTheme({
  palette: {
    primary: {
      main: green[500]
    },
    secondary: {
      main: yellow[700]
    }
  },
  typography: {
    body2: {
      fontSize: "1.1rem"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 30
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  overrides: {
    MuiOutlinedInput: {
        root: {
            color: yellow[600],
            '& $notchedOutline': {
                borderColor: yellow[300],
            },
            '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                borderColor: yellow[400],
                borderWidth: '4px',
            },
            '&$focused $notchedOutline': {
                borderColor:  yellow[100]
            },
            '&$focused' : {
                color: yellow[100]
            },
            '&:hover:not($disabled):not($focused):not($error)': {
                color: yellow[400]
            }
        }
    },
    MuiFormLabel: {
      root: {
            color: yellow[300],
           '&$focused': {
               color: yellow[100],
           }
      }
    },
    MuiIconButton: {
        root: {
            color: yellow[300]
        }
    },
    MuiButton: {
      root: {
        /*textTransform: "none",
        padding: "2px 10px",
        fontSize: '24px',*/
      },
      containedSecondary: {
           '&:hover': {
                backgroundColor: yellow[300],
            }
      },
      fullWidth: {
        maxWidth: "300px"
      }
    }
  },
  props: {
    MuiButton: {
      disableRipple: true,
      variant: "contained",
      color: "secondary"
    },
    MuiTextField: {
      variant: "outlined",
      InputLabelProps: {
        shrink: true,
      }
    },
    MuiPaper: {
      elevation: 12
    },
    MuiCard: {
      elevation: 12
    }
  }
});